* {
  /* Reset the sizing */
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eaf6f1;
}
#root {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.heui-error {
  color: #b50000;
}

:root {
  /* Theming for Amplify UI */
  --amplify-primary-color: #005A8A;
  --amplify-primary-tint:  #005A8A;
  --amplify-primary-shade: #005A8A;

  /* aws-amplify/ui-react 3.x */
  --amplify-components-button-primary-background-color: #005A8A !important;
  --amplify-components-authenticator-router-box-shadow: none !important;
  --amplify-components-authenticator-router-background-color: none !important;
  --amplify-components-authenticator-router-border-style: none !important;

  --amplify-font-family: "Roboto", sans-serif;
}
[data-amplify-router] { 
  background-color: white !important;
}

amplify-authenticator {
  /* Allow Authenticator to fit in page */
  --container-height: unset;
  --padding: 16px;
}
